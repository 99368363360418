import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FieldArray, Form, Formik, setIn } from 'formik';
import { clone, cloneDeep, pick } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FieldSetAttachment from './FieldSetAttachment';
import { FieldSetTelemetry } from './FieldSetTelemetry';
import FieldSetCommanding from './FieldSetCommanding';
import FieldSetProcedureLink from './FieldSetProcedureLink';
import JumpToEdit from './JumpTo/JumpToEdit';
import TableInputEdit from './TableInput/TableInputEdit';
import procedureUtil from '../lib/procedureUtil';
import stepConditionals, { CONDITIONAL_TYPE } from 'shared/lib/stepConditionals';
import FieldSetProcedureField from './FieldSetProcedureField';
import { useMixpanel } from '../contexts/MixpanelContext';
import ExpandCollapseCaret from './ExpandCollapse/ExpandCollapseCaret';
import { ProcedureContentBlockTypes, PropertyTypes } from 'shared/lib/types/blockTypes';
import OverlayUploadFileDrop from './OverlayUploadFileDrop';
import MenuContext from './MenuContext';
import FieldSetStepDetail from './FieldSetStepDetail';
import FieldSetStepSignoffArray from './FieldSetStepSignoffArray';
import { useSettings } from '../contexts/SettingsContext';
import { useProcedureContext } from '../contexts/ProcedureContext';
import { DefaultStepDetailDefinitions, DefaultValuesByStepDetailType } from './StepDetailTypes';
import getFileInputBlock from '../hooks/useFileInput';
import FieldSetProcedureStepHeader from './FieldSetProcedureStepHeader';
import FieldSetStepDependencies from './StepDependency/FieldSetStepDependencies';
import { SNIPPET_STEP_DELETED_AND_DETACHED } from '../lib/snippetUtil';
import ReferenceBlockEdit from './Blocks/ReferenceBlockEdit';
import { arrayMoveMutable } from 'array-move';
import FieldSetStepConditionals from './StepConditionals/FieldSetStepConditionals';
import ExpressionBlockEdit from './Expression/ExpressionBlockEdit';
import signoffUtil from 'shared/lib/signoffUtil';
import { getRedlineDocsSortedLatestToEarliest, getStepBlockRedlineMap } from '../lib/redlineUtil';
import FieldSetText from './FieldSetText';
import FieldSetProcedureBlock from './Blocks/FieldSetProcedureBlock';
import PartKitFieldSet from '../manufacturing/components/PartKitFieldSet';
import PartBuildFieldSet from '../manufacturing/components/PartBuildFieldSet';
import InventoryDetailInputFieldSet from '../manufacturing/components/InventoryDetailInputFieldSet';
import PartUsageFieldSet from '../manufacturing/components/PartUsageFieldSet';
import AddContentMenu from './ContentMenu/AddContentMenu';
import { capitalizeFirstLetter } from 'shared/lib/text';
import getBlockAddContentItems from './ContentMenu/blockAddContentItems';
import { Actions } from './ContentMenu/addContentTypes';
import Selectable, { Boundary } from './Selection/Selectable';
import { copyItemToClipboard, selectClipboardItem } from '../contexts/proceduresSlice';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import FlashMessage from './FlashMessage';
import clipboardUtil from '../lib/clipboardUtil';
import DropWrapper from './DropWrapper';
import DragWrapper from './DragWrapper';
import TestCasesBlockEdit from '../testing/components/TestCasesBlockEdit';
import DraftToolCheckOutIn from '../manufacturing/components/Tools/DraftToolCheckOutIn';
import FieldSetStepDuration from './FieldSetStepDuration';
import DraftToolUsage from '../manufacturing/components/Tools/DraftToolUsage';
import { selectOfflineInfo } from '../app/offline';
import FieldSetStepTimer from './FieldSetStepTimer';
import { canBatchStepsInProcedure } from '../lib/batchSteps';
import { EDIT_STICKY_HEADER_HEIGHT_REM } from './EditToolbar';
import TestPointSelectionModal from '../testing/components/TestPointSelectionModal';
import { getInitialTestPointTable } from './TableInput/TableInputConstants';
import FieldInputTableEdit from './FieldInputTableEdit';
import ProcedureStepSettingsMenu from './ProcedureStepSettingsMenu';
import { isStepSettingEnabled, setDisabledStepSettings } from 'shared/lib/procedureUtil';
import { MAX_FILE_SIZE } from 'shared/lib/types/api/files/requests';
import { MAX_FILE_SIZE_EXCEEDED_MESSAGE } from '../attachments/service';
import VirtualizedElement from '../elements/Virtualized/VirtualizedElement';
import { MainScrollPanelId } from '../elements/SidebarLayout';
import { PROCEDURE_EDIT_VIRTUALIZED_ELEMENTS } from '../screens/ProcedureEdit';
import { getRedlineFromDoc, REDLINE_TYPE } from 'shared/lib/redlineUtil';
import BlockRedline from './SuggestedEdits/BlockRedline';

const FADE_OUT_TIMEOUT_MS = 400;
const SKIP_VIRTUALIZATION_BLOCK_TYPES = [ProcedureContentBlockTypes.Attachment];

/**
 * @typedef {Object} FieldSetProcedureStepProps
 * @property {Boolean} isPending - If true, renders the step key as "--".
 * @property {number | undefined} [customStepKey] - Optional key for the step, defaults to undefined.
 */

/**
 * Component for rendering the form fields for a given procedure step.
 * @param {FieldSetProcedureStepProps & any} props
 */
const FieldSetProcedureStep = ({
  step,
  stepIndex,
  sectionIndex,
  isPending,
  errors,
  isCollapsed,
  isStepHeadersEnabled,
  precedingStepId, // KURT: this is not coming from any parent
  enabledContentTypes,
  onFieldRefChanged,
  onStepCollapse,
  onStepFormChanged,
  onAcceptRedlineField,
  onRejectRedlineField,
  onAcceptRedlineBlock,
  onRejectRedlineBlock,
  configurePartKitBlock,
  configurePartBuildBlock,
  onRemoveStepHeader,
  redlines,
  changedStepIdSet,
  setChangedStepIdSet,
  areDependenciesEnabled = false,
  customStepKey = undefined,
  hideDetails = false,
  optimize = false,
  showSettings = true,
  canModifyContentStructure = true,
}) => {
  const { config, getSetting } = useSettings();
  const { procedure, getInitialConditionals } = useProcedureContext();
  const optionalStepDetailKeys = useMemo(() => {
    if (!config || !config.step_details) {
      return [];
    }

    return Object.keys(config.step_details);
  }, [config]);

  const fileInputRef = useRef(null);
  const dependenciesRef = useRef(null);
  const conditionalsRef = useRef(null);
  const { mixpanel } = useMixpanel();
  const [isAddDetailsMenuVisible, setIsAddDetailsMenuVisible] = useState(false);
  const [addFileIndex, setAddFileIndex] = useState(-1);
  const { currentTeamId } = useDatabaseServices();
  const online = useSelector((state) => selectOfflineInfo(state).online);
  const canBatchSteps = canBatchStepsInProcedure(procedure);

  /**
   * Track step errors internally, since validation can be triggered at the
   * procedure level (review procedure) and at the step level (save snippet).
   *
   * `stepErrors` should be the results of validating this step individually,
   * but allowing the procedure to override it with the `errors` prop.
   */
  const [stepErrors, setStepErrors] = useState(errors);
  const [testPointSelectorContext, setTestPointSelectorContext] = useState(
    /** @type {{visible: boolean; blockIndex: null | number}} */ ({
      visible: false,
      blockIndex: null,
    })
  );

  // Override step errors with procedure validation.
  useEffect(() => {
    setStepErrors(errors);
  }, [errors]);

  const syncStepToProcedure = useCallback(
    (values) => {
      setDisabledStepSettings(values);
      onStepFormChanged(values, sectionIndex, stepIndex);
    },
    [onStepFormChanged, sectionIndex, stepIndex]
  );

  const toggleIsStepCollapsed = useCallback(() => {
    onStepCollapse(step.id, !isCollapsed);
  }, [isCollapsed, onStepCollapse, step.id]);

  // TODO: DRY this up, possibly by creating a MixpanelContext.js
  const mixpanelTrack = useCallback(
    (name, options) => {
      if (mixpanel && name) {
        mixpanel.track(name, options);
      }
    },
    [mixpanel]
  );

  /*
   * Duplicated from FieldSetProcedureSection.js and redefined here to avoid
   * re-rendering component when a sibling step changes.
   * TODO: DRY this up, possibly by creating a MixpanelContext.js
   */
  const trackedCallback = useCallback(
    (trackingKey, callback) => (event) => {
      mixpanelTrack(trackingKey);
      callback(event);
    },
    [mixpanelTrack]
  );

  const hideAddDetailsMenu = useCallback(() => {
    setIsAddDetailsMenuVisible(false);
  }, []);

  const showAddDetailsMenu = useCallback(() => {
    setIsAddDetailsMenuVisible(true);
  }, []);

  const getStepDetailDefinition = useCallback(
    (stepDetailKey) => {
      if (DefaultStepDetailDefinitions[stepDetailKey]) {
        return DefaultStepDetailDefinitions[stepDetailKey];
      }

      // If config doc or step detail definitions do not exist in settings, return undefined.
      if (!config || !config.step_details) {
        return undefined;
      }

      return config.step_details[stepDetailKey];
    },
    [config]
  );

  const getDefaultStepDetailValue = useCallback(
    (stepDetailKey) => {
      const stepDetailDefinition = getStepDetailDefinition(stepDetailKey);

      if (!stepDetailDefinition) {
        return undefined;
      }

      return DefaultValuesByStepDetailType[stepDetailDefinition.type];
    },
    [getStepDetailDefinition]
  );

  useEffect(() => {
    if (isAddDetailsMenuVisible) {
      document.addEventListener('click', hideAddDetailsMenu);
    }
    // Remove listener when menu visibility changes or component unmounts.
    return () => {
      document.removeEventListener('click', hideAddDetailsMenu);
    };
  }, [isAddDetailsMenuVisible, hideAddDetailsMenu]);

  // Control the fade-in animation for updated items.
  useEffect(() => {
    if (changedStepIdSet?.has(step.id)) {
      setTimeout(
        () =>
          setChangedStepIdSet((oldSet) => {
            oldSet.delete(step.id);
            return oldSet;
          }),
        FADE_OUT_TIMEOUT_MS
      );
    }
  }, [changedStepIdSet, setChangedStepIdSet, step.id]);

  const dispatch = useDispatch();
  const [flashMessage, setFlashMessage] = useState('');
  const clipboardItem = useSelector((state) => selectClipboardItem(state, currentTeamId));
  const clipboardBlock = useMemo(() => {
    if (!clipboardItem) {
      return undefined;
    }
    const clipboardBlock = clipboardUtil.getBlockFromClipboardItem(clipboardItem);
    // @ts-ignore enabledContentTypes should be of type string[]
    if (!clipboardBlock || (enabledContentTypes && !enabledContentTypes.includes(clipboardBlock.type))) {
      return undefined;
    }
    return clipboardBlock;
  }, [clipboardItem, enabledContentTypes]);

  const onRemoveContent = useCallback(
    (index, step, syncToProcedure) => {
      if (!canModifyContentStructure) {
        return;
      }
      mixpanelTrack('Remove Content');
      const stepCopy = cloneDeep(step);
      const contentId = stepCopy.content[index].id;

      stepCopy.content.splice(index, 1);
      procedureUtil.removeContentReferences(stepCopy, [contentId]);
      syncToProcedure(stepCopy);
    },
    [canModifyContentStructure, mixpanelTrack]
  );

  const onCopyBlock = useCallback(
    ({ block }) => {
      mixpanelTrack('Copy Block');
      const copiedBlock = procedureUtil.copyBlock(block);
      const clipboardItemBlock = clipboardUtil.createClipboardItemBlock(copiedBlock);
      dispatch(copyItemToClipboard(currentTeamId, clipboardItemBlock));
      setFlashMessage('Content block copied');
    },
    [currentTeamId, dispatch, mixpanelTrack]
  );

  const onCutBlock = useCallback(
    ({ block, blockIndex }) => {
      if (!canModifyContentStructure) {
        return;
      }
      mixpanelTrack('Cut Block');
      const copiedBlock = procedureUtil.copyBlock(block);
      const clipboardItemBlock = clipboardUtil.createClipboardItemBlock(copiedBlock);
      dispatch(copyItemToClipboard(currentTeamId, clipboardItemBlock));
      onRemoveContent(blockIndex, step, syncStepToProcedure);
      setFlashMessage('Content block cut');
    },
    [canModifyContentStructure, currentTeamId, dispatch, mixpanelTrack, step, syncStepToProcedure, onRemoveContent]
  );

  const onPasteBlock = useCallback(
    ({ blockIndex }) => {
      if (!clipboardBlock || !canModifyContentStructure) {
        return;
      }
      mixpanelTrack('Paste Block');
      const updatedStep = cloneDeep(step);
      const blockCopy = procedureUtil.copyBlock(clipboardBlock);
      updatedStep.content.splice(blockIndex + 1, 0, blockCopy);
      syncStepToProcedure(updatedStep);
    },
    [canModifyContentStructure, clipboardBlock, mixpanelTrack, step, syncStepToProcedure]
  );

  const onPasteBlockIntoStepHeader = useCallback(
    ({ headerIndex }) => {
      // @ts-ignore enabledContentTypes should be of type string[]
      if (
        !canModifyContentStructure ||
        !clipboardBlock ||
        // @ts-expect-error we only check for these two block types, the rest as not of interest
        ![ProcedureContentBlockTypes.Alert, ProcedureContentBlockTypes.Text].includes(clipboardBlock.type)
      ) {
        return;
      }
      mixpanelTrack('Paste Block');
      const updatedStep = cloneDeep(step);
      const blockCopy = procedureUtil.copyBlock(clipboardBlock);
      updatedStep.headers[headerIndex].content.push(blockCopy);
      syncStepToProcedure(updatedStep);
    },
    [canModifyContentStructure, clipboardBlock, mixpanelTrack, step, syncStepToProcedure]
  );

  /**
   * Sets detail value to empty string (default), and disables option in menu.
   * Note: A non undefined value means the detail field will be rendered in the step.
   * @param {String} detailProperty - One of values from DefaultStepDetailProperties or step_details defined in config doc.
   * @param {Function} setFieldValue - Formik function used to edit step form.
   */
  const showDetailField = useCallback(
    (detailProperty) => {
      let stepCopy = setIn(step, detailProperty, getDefaultStepDetailValue(detailProperty));
      if (detailProperty === 'duration') {
        stepCopy = setIn(stepCopy, 'expected_duration', '');
      }
      syncStepToProcedure(stepCopy);
    },
    [step, getDefaultStepDetailValue, syncStepToProcedure]
  );

  /**
   * Sets detail value to undefined and sets option in menu to enabled.
   * Note: An undefined value means the detail field will be hidden in the step.
   * @param {String} detailProperty - One of DefaultStepDetailProperties or step_details defined in config doc.
   */
  const onRemoveDetail = useCallback(
    (detailProperty) => {
      let stepCopy = setIn(step, detailProperty, undefined);

      // Duration also manages the 'expected_duration' field, so remove it also
      if (detailProperty === 'duration') {
        stepCopy = setIn(stepCopy, 'expected_duration', undefined);
      }
      if (stepCopy.conditionals) {
        stepCopy.conditionals = stepCopy.conditionals.filter(
          (conditional) => conditional.source_type !== detailProperty
        );
      }
      syncStepToProcedure(stepCopy);
    },
    [step, syncStepToProcedure]
  );

  const allStepDetails = useMemo(() => {
    const standardStepDetails = Object.values(DefaultStepDetailDefinitions);

    // If config doc or step details do not exist, return standard step details.
    if (!config || !config.step_details) {
      return standardStepDetails;
    }

    const optionalStepDetails = Object.values(config.step_details);

    return [...standardStepDetails, ...optionalStepDetails];
  }, [config]);

  const getDetailsContextActions = useCallback(() => {
    const isDisabled = (detailId, step) => {
      if (detailId === 'duration') {
        return 'duration' in step || 'expected_duration' in step;
      }
      return detailId in step;
    };

    const stepDetailActions = allStepDetails.map((stepDetailDefinition) => ({
      type: 'label',
      label: stepDetailDefinition.title,
      data: {
        icon: stepDetailDefinition.icon || 'cog',
        disabled: isDisabled(stepDetailDefinition.id, step),
        onClick: () => {
          showDetailField(stepDetailDefinition.id);
        },
      },
    }));

    return [stepDetailActions];
  }, [allStepDetails, showDetailField, step]);

  const onDragStart = useCallback(() => {
    // Blur any active inputs, otherwise they behave weirdly during dragging

    // @ts-expect-error Blur is defined for HTMLElement but not for Element
    document.activeElement?.blur();
  }, []);

  // TODO: DRY up
  const onDragEnd = useCallback(
    (result, arrayHelpers, block, syncBlock) => {
      mixpanelTrack('Content Reordered', { Using: 'Drag and Drop' });
      const { source, destination } = result;
      if (!destination) {
        return;
      }
      if (destination.droppableId === source.droppableId && destination.index === source.index) {
        return;
      }

      // Need arrayHelpers to update formik state right away, to avoid flashing of old positions.
      arrayHelpers.move(source.index, destination.index);

      const blockCopy = cloneDeep(block);
      arrayMoveMutable(blockCopy.content, source.index, destination.index);

      syncBlock(blockCopy);
    },
    [mixpanelTrack]
  );

  // returns an object with just the properties in formFields from step
  const initialValues = useMemo(() => {
    const standardFields = [
      'name',
      'timing',
      'duration',
      'timer',
      'expected_duration',
      'location',
      'channel',
      'operators',
      'content',
      'step_field_redlines',
      'signoffs',
      'headers',
      'dependencies',
      'conditionals',
      'runAsBatch',
      'skip_step_enabled',
      'repeat_step_enabled',
      'step_suggest_edits_enabled',
      'run_only',
    ];

    const stepCopy = pick(step, [...standardFields, ...optionalStepDetailKeys]);
    // Add display style and caption properties to old procedures that don't have them
    stepCopy.content.forEach((block) => {
      if (block.type === 'attachment') {
        block.display_style = block.display_style || 'inline';
        block.caption = block.caption || '';
      }
    });
    return stepCopy;
  }, [step, optionalStepDetailKeys]);

  const fieldRef = useCallback((field) => (element) => onFieldRefChanged(field, element), [onFieldRefChanged]);

  const getOnAddDependencies = useCallback(
    (step) => {
      mixpanelTrack('Dependencies Added');
      const stepCopy = cloneDeep(step);
      stepCopy.dependencies = [procedureUtil.newDependency()];

      syncStepToProcedure(stepCopy);
      dependenciesRef.current?.scrollIntoView({ behavior: 'smooth' });
    },
    [mixpanelTrack, syncStepToProcedure, dependenciesRef]
  );

  const getOnAddFlowControl = useCallback(
    (step) => {
      mixpanelTrack('Flow Control Added');
      const stepCopy = cloneDeep(step);
      stepCopy.conditionals = getInitialConditionals(step, null, CONDITIONAL_TYPE.STEP);

      syncStepToProcedure(stepCopy);
      conditionalsRef.current?.scrollIntoView({ behavior: 'smooth' });
    },
    [mixpanelTrack, getInitialConditionals, syncStepToProcedure, conditionalsRef]
  );

  const onAddBlock = useCallback(
    (container, syncBlock, index, blockType, subType, blockInitializer = procedureUtil.newInitialBlock) => {
      const containerCopy = cloneDeep(container);
      const contentBlock = blockInitializer(blockType, subType);

      // Set part for part kit and part build.
      if (blockType === 'part_kit') {
        configurePartKitBlock(contentBlock);
      }
      if (blockType === 'part_build') {
        configurePartBuildBlock(contentBlock);
      }

      if (index === undefined) {
        containerCopy.content.push(contentBlock);
      } else {
        containerCopy.content.splice(index + 1, 0, contentBlock);
      }

      syncBlock(containerCopy);
    },
    [configurePartKitBlock, configurePartBuildBlock]
  );

  const onAddTestPoints = useCallback(
    (testPoints) => {
      if (testPointSelectorContext.blockIndex === null) {
        return;
      }
      const blockType = ProcedureContentBlockTypes.TableInput;
      const subType = 'test_point';
      const blockInitializer = () => {
        const defaultList = Object.values(config?.lists ?? {}).find((list) => list.id === 'ls_default_quality');
        return getInitialTestPointTable({
          testPoints,
          listName: defaultList?.name ?? '',
          listId: defaultList?.id ?? '',
        });
      };

      onAddBlock(step, syncStepToProcedure, testPointSelectorContext.blockIndex, blockType, subType, blockInitializer);
      setTestPointSelectorContext({ visible: false, blockIndex: null });
    },
    [config?.lists, onAddBlock, step, syncStepToProcedure, testPointSelectorContext.blockIndex]
  );

  const onAddHeaderBlock = useCallback(
    (container, syncBlock, index, blockType, subType) => {
      const blockName = capitalizeFirstLetter(subType || blockType);
      mixpanelTrack(`Add Header ${blockName}`);
      onAddBlock(container, syncBlock, index, blockType, subType);
    },
    [onAddBlock, mixpanelTrack]
  );

  const onContentMenuClick = useCallback(
    (menuItem, blockIndex, localStep, syncToProcedure) => {
      if (!canModifyContentStructure) {
        return;
      }
      switch (menuItem.action) {
        case Actions.DeleteBlock:
          return onRemoveContent(blockIndex, localStep, syncToProcedure);
        case Actions.CopyBlock:
          return onCopyBlock({ block: localStep.content[blockIndex] });
        case Actions.CutBlock:
          return onCutBlock({ block: localStep.content[blockIndex], blockIndex });
        case Actions.PasteBlock:
          return onPasteBlock({ blockIndex });
        case Actions.AddBlock:
          mixpanelTrack(`Add ${capitalizeFirstLetter(menuItem.subType || menuItem.blockType)}`);

          if (menuItem.blockType === ProcedureContentBlockTypes.Attachment) {
            setAddFileIndex(blockIndex);
            return fileInputRef && fileInputRef.current && fileInputRef.current.click();
          }

          if (menuItem.blockType === PropertyTypes.Conditionals) {
            return getOnAddFlowControl(step);
          }

          if (menuItem.blockType === PropertyTypes.Dependencies) {
            return getOnAddDependencies(step);
          }

          if (menuItem.blockType === ProcedureContentBlockTypes.TestCases) {
            setTestPointSelectorContext({ visible: true, blockIndex });
            return;
          }

          return onAddBlock(step, syncStepToProcedure, blockIndex, menuItem.blockType, menuItem.subType);
        default:
          return;
      }
    },
    [
      canModifyContentStructure,
      onAddBlock,
      getOnAddFlowControl,
      getOnAddDependencies,
      mixpanelTrack,
      onCopyBlock,
      onCutBlock,
      onPasteBlock,
      onRemoveContent,
      step,
      syncStepToProcedure,
    ]
  );

  const onKeyboard = useCallback(
    (event, boundary, index, step) => {
      if (!canModifyContentStructure) {
        return;
      }
      if (boundary === Boundary.StepHeader) {
        if (event.code === 'Backspace' || event.code === 'Delete') {
          onRemoveStepHeader();
        } else if ((event.metaKey || event.ctrlKey) && event.code === 'KeyV') {
          if (clipboardBlock) {
            onPasteBlockIntoStepHeader({ headerIndex: index });
          }
        }
      } else if (boundary === Boundary.ContentBlock) {
        if (event.code === 'Backspace' || event.code === 'Delete') {
          onRemoveContent(index, step, syncStepToProcedure);
        } else if ((event.metaKey || event.ctrlKey) && event.code === 'KeyC') {
          onCopyBlock({ block: step.content[index] });
        } else if ((event.metaKey || event.ctrlKey) && event.code === 'KeyX') {
          onCutBlock({ block: step.content[index], blockIndex: index });
        } else if ((event.metaKey || event.ctrlKey) && event.code === 'KeyV') {
          onPasteBlock({ blockIndex: index });
        }
      }
    },
    [
      canModifyContentStructure,
      clipboardBlock,
      onCopyBlock,
      onCutBlock,
      onPasteBlock,
      onPasteBlockIntoStepHeader,
      onRemoveContent,
      onRemoveStepHeader,
      syncStepToProcedure,
    ]
  );

  const getOnRemoveDependencies = useCallback(() => {
    return trackedCallback('Remove Dependencies', () => {
      const stepCopy = cloneDeep(step);
      stepCopy.dependencies = [];
      syncStepToProcedure(stepCopy);
    });
  }, [trackedCallback, step, syncStepToProcedure]);

  const getOnRemoveFlowControl = useCallback(() => {
    return trackedCallback('Remove Flow Control', () => {
      const stepCopy = cloneDeep(step);
      stepCopy.conditionals = [];
      syncStepToProcedure(stepCopy);
    });
  }, [trackedCallback, step, syncStepToProcedure]);

  const handleFileInputChange = useCallback(
    (/** @type {import('react').ChangeEvent<HTMLInputElement>} */ event) => {
      const file = event.currentTarget.files?.[0];
      if (file && file.size > MAX_FILE_SIZE) {
        event.target.value = '';
        window.alert(MAX_FILE_SIZE_EXCEEDED_MESSAGE);
        return;
      }

      const contentBlock = getFileInputBlock(event, fileInputRef);
      const stepCopy = cloneDeep(step);

      stepCopy.content.splice(addFileIndex + 1, 0, contentBlock);

      syncStepToProcedure(stepCopy);
    },
    [step, syncStepToProcedure, addFileIndex]
  );

  const addAttachment = useCallback(
    (attachmentBlock) => {
      const stepCopy = cloneDeep(step);
      stepCopy.content.push(attachmentBlock);
      syncStepToProcedure(stepCopy);
    },
    [step, syncStepToProcedure]
  );

  /**
   * @type {() => (pathValueList: Array<{path: string, value: unknown}>) => void}
   */
  const getSetFieldValueListAndSync = useCallback(() => {
    return (pathValueList) => {
      let updatedStep = clone(step);
      pathValueList.forEach(({ path, value }) => {
        updatedStep = setIn(updatedStep, path, value);
        // Keep conditional state values in sync with list options.
        if (updatedStep.conditionals && updatedStep.conditionals.length > 0) {
          const updatedContentConditional = updatedStep.conditionals.find(stepConditionals.isContentConditional);
          const contentId = updatedContentConditional?.content_id;

          const conditionalSourceBlock = contentId && updatedStep.content.find((block) => block.id === contentId);

          const updatedDurationConditional = updatedStep.conditionals.find(
            (conditional) => conditional.source_type === CONDITIONAL_TYPE.DURATION
          );
          if (updatedDurationConditional) {
            if (!updatedStep.duration || !updatedStep.expected_duration) {
              updatedStep.conditionals = updatedStep.conditionals.filter(
                (conditional) => conditional.source_type !== CONDITIONAL_TYPE.DURATION
              );
            } else {
              const conditionals = getInitialConditionals(
                updatedStep,
                conditionalSourceBlock,
                CONDITIONAL_TYPE.DURATION
              );
              stepConditionals.updateConditionalStates(updatedStep, conditionals);
            }
          }

          if (conditionalSourceBlock) {
            /*
             * Extract the updated content block index from the path being set, so it can be
             * compared to the source content block in the conditional.  If the conditional
             * has a content block which is updated to be invalid (e.g. removing the rule on
             * a number field) we clear out the conditional.
             */
            const sourceType = updatedContentConditional?.source_type;
            const contentRegExp = /^content\[(\d+)\].*/;
            const matchResult = contentRegExp.exec(path);
            let isMatchingIndex = false;
            if (matchResult && matchResult.length >= 2) {
              const contentIndex = Number(matchResult[1]);
              if (
                !isNaN(contentIndex) &&
                updatedStep.content.length > contentIndex &&
                updatedStep.content[contentIndex].id === conditionalSourceBlock.id
              ) {
                isMatchingIndex = true;
              }
            }
            if (isMatchingIndex && value && value['type'] === 'telemetry') {
              // Change conditional when rule on telemetry block is changed
              if ((value['key'] === 'parameter' && value['rule'] === '') || value['key'] === 'custom') {
                // rule was removed
                procedureUtil.removeContentReferences(updatedStep, [contentId]);
              } else if (value['key'] === 'parameter' && value['rule'] !== '') {
                const newConditionalIsTernary = value['rule'] === 'range';
                const existingConditionalIsTernary =
                  stepConditionals._getConditionalType(step.conditionals) === CONDITIONAL_TYPE.CONTENT_TERNARY;
                if (newConditionalIsTernary !== existingConditionalIsTernary) {
                  // rule was changed from binary to ternary (or vice versa), change the conditional type
                  const newSourceType = newConditionalIsTernary
                    ? CONDITIONAL_TYPE.CONTENT_TERNARY
                    : CONDITIONAL_TYPE.CONTENT_BINARY;
                  updatedStep.conditionals = getInitialConditionals(step, conditionalSourceBlock, newSourceType);
                }
              }
            } else if (isMatchingIndex && path.endsWith('rule') && value) {
              // Change conditional when rule on number field input is changed
              if (value['op'] === '') {
                // rule was removed
                procedureUtil.removeContentReferences(updatedStep, [contentId]);
              } else {
                const newConditionalIsTernary = value['op'] === 'range';
                const existingConditionalIsTernary =
                  stepConditionals._getConditionalType(step.conditionals) === CONDITIONAL_TYPE.CONTENT_TERNARY;
                if (newConditionalIsTernary !== existingConditionalIsTernary) {
                  // rule was changed from binary to ternary (or vice versa), change the conditional type
                  const newSourceType = newConditionalIsTernary
                    ? CONDITIONAL_TYPE.CONTENT_TERNARY
                    : CONDITIONAL_TYPE.CONTENT_BINARY;
                  updatedStep.conditionals = getInitialConditionals(step, conditionalSourceBlock, newSourceType);
                }
              }
            } else {
              const conditionals = getInitialConditionals(step, conditionalSourceBlock, sourceType);
              stepConditionals.mergeInitialConditionals(updatedStep, conditionals);
            }
          }
        }
      });
      syncStepToProcedure(updatedStep);
    };
  }, [step, syncStepToProcedure, getInitialConditionals]);

  /**
   * @type {function(): (path: string, value: unknown) => void}
   */
  const getSetFieldValueAndSync = useCallback(() => {
    const setFieldValueListAndSync = getSetFieldValueListAndSync();
    return (path, value) => {
      return setFieldValueListAndSync([{ path, value }]);
    };
  }, [getSetFieldValueListAndSync]);

  const getContentErrors = useCallback(
    (contentId) => {
      if (!stepErrors || !stepErrors.content) {
        return null;
      }
      return stepErrors.content[contentId];
    },
    [stepErrors]
  );

  const isContentTypeEnabled = useCallback(
    (contentType) => {
      if (
        [
          ProcedureContentBlockTypes.PartKit,
          ProcedureContentBlockTypes.PartBuild,
          ProcedureContentBlockTypes.ToolCheckOut,
          ProcedureContentBlockTypes.ToolCheckIn,
        ].includes(contentType)
      ) {
        return false;
      }
      if (!enabledContentTypes) {
        return true;
      }
      return enabledContentTypes.includes(contentType);
    },
    [enabledContentTypes]
  );

  const syncHeaderToProcedure = useCallback(
    (headerIndex) => {
      return (updatedHeader) => {
        const stepCopy = cloneDeep(step);
        stepCopy.headers[headerIndex] = updatedHeader;

        syncStepToProcedure(stepCopy);
      };
    },
    [step, syncStepToProcedure]
  );

  const syncDependenciesToProcedure = useCallback(
    (updatedDependencies) => {
      const stepCopy = cloneDeep(step);
      stepCopy.dependencies = updatedDependencies;

      syncStepToProcedure(stepCopy);
    },
    [step, syncStepToProcedure]
  );

  const syncSignoffsToProcedure = useCallback(
    (updatedSignoffs) => {
      const stepCopy = cloneDeep(step);
      stepCopy.signoffs = updatedSignoffs;

      // Update the default step conditionals if signoff requirements change
      if (signoffUtil.isSignoffRequired(updatedSignoffs) !== signoffUtil.isSignoffRequired(step.signoffs)) {
        const conditionals = getInitialConditionals(stepCopy, null, CONDITIONAL_TYPE.STEP);
        stepConditionals.mergeInitialConditionals(stepCopy, conditionals);
      }
      // If a step switches from having no signoffs to having signoffs, reset settings to match procedure defaults.
      if (!signoffUtil.isSignoffRequired(step.signoffs) && signoffUtil.isSignoffRequired(stepCopy.signoffs)) {
        stepCopy.skip_step_enabled = undefined;
        stepCopy.repeat_step_enabled = undefined;
      }

      syncStepToProcedure(stepCopy);
    },
    [step, syncStepToProcedure, getInitialConditionals]
  );

  const onAcceptStepRedlineField = useCallback(
    (redline, fieldName) =>
      onAcceptRedlineField(`sections[${sectionIndex}].steps[${stepIndex}]`, 'step', redline, fieldName),
    [onAcceptRedlineField, sectionIndex, stepIndex]
  );
  const onRejectStepRedlineField = useCallback(
    (redline, fieldName) =>
      onRejectRedlineField(`sections[${sectionIndex}].steps[${stepIndex}]`, 'step', redline, fieldName),
    [onRejectRedlineField, sectionIndex, stepIndex]
  );

  const onAcceptStepRedlineBlock = useCallback(
    (path, block, redline, sourceContentId) =>
      onAcceptRedlineBlock(`sections[${sectionIndex}].steps[${stepIndex}].${path}`, block, redline, sourceContentId),
    [onAcceptRedlineBlock, sectionIndex, stepIndex]
  );
  const onRejectStepRedlineBlock = useCallback(
    (path, block, redline) =>
      onRejectRedlineBlock(`sections[${sectionIndex}].steps[${stepIndex}].${path}`, block, redline),
    [onRejectRedlineBlock, sectionIndex, stepIndex]
  );

  /**
   * No map is needed because there is currently only one type of field redline: "name"
   * @type {Array<import('shared/lib/types/views/redlines').StepFieldRedline>}
   */
  const stepFieldRedlines = useMemo(() => {
    return redlines
      ? redlines.filter(
          (redline) =>
            (redline.type === REDLINE_TYPE.FULL_STEP_REDLINE && redline.field) ||
            /** @type {import('shared/lib/types/views/procedures').RunStepFieldRedline} */ (getRedlineFromDoc(redline))
              .field
        )
      : [];
  }, [redlines]);

  const stepBlockRedlineMap = useMemo(() => {
    return getStepBlockRedlineMap(redlines);
  }, [redlines]);

  /**
   * @type {(contentId: string) => Array<import('shared/lib/types/views/redlines').StepBlockRedline>}
   */
  const getStepBlockRedlines = useCallback(
    (contentId) => {
      return getRedlineDocsSortedLatestToEarliest(stepBlockRedlineMap.get(contentId) ?? []);
    },
    [stepBlockRedlineMap]
  );

  const stepKey = useMemo(() => {
    if (customStepKey !== undefined) {
      return customStepKey;
    }
    if (!config) {
      return '';
    }
    return isPending
      ? '--'
      : procedureUtil.displaySectionStepKey(sectionIndex, stepIndex, config && config.display_sections_as);
  }, [config, isPending, sectionIndex, stepIndex, customStepKey]);

  const showSubstepLabels = useMemo(() => {
    if (!config) {
      return false;
    }
    return getSetting('show_substep_labels', false);
  }, [config, getSetting]);

  const subStepKeys = useMemo(() => {
    let currentSubStepKey = 1;
    const numbers = {};
    step.content.forEach((content) => {
      const { substepKey: key, nextNumber } = procedureUtil.displaySubStepKey(stepKey, currentSubStepKey, content.type);
      numbers[content.id] = key;
      currentSubStepKey = nextNumber;
    });
    return numbers;
  }, [step.content, stepKey]);

  /**
   *
   * @type {() => ((settings: import('shared/lib/types/views/procedures').StepSettings) => void)}
   */
  const getSetSettings = useCallback(() => {
    const setFieldValueList = getSetFieldValueListAndSync();
    return (settings) => {
      const fieldValueList = Object.entries(settings).map(([path, value]) => ({
        path,
        value,
      }));
      return setFieldValueList(fieldValueList);
    };
  }, [getSetFieldValueListAndSync]);

  const getSkipStepDisabledData = useCallback(() => {
    if (!canModifyContentStructure) {
      return {
        tooltip: 'Cannot modify settings in this mode.',
        isDisabled: true,
      };
    }
    if (!signoffUtil.isSignoffRequired(step.signoffs)) {
      return {
        tooltip: 'Cannot skip steps with no signoffs',
        isDisabled: true,
      };
    }
    return {
      isDisabled: false,
    };
  }, [canModifyContentStructure, step.signoffs]);

  const getRepeatStepDisabledData = useCallback(
    (values) => {
      if (!canModifyContentStructure) {
        return {
          tooltip: 'Cannot modify settings in this mode.',
          isDisabled: true,
        };
      }

      if (isPending) {
        return {
          tooltip: 'Cannot repeat added steps',
          isDisabled: true,
        };
      }

      if (values.runAsBatch) {
        return {
          tooltip: 'Batch steps cannot be repeated.',
          isDisabled: true,
        };
      }

      if (!signoffUtil.isSignoffRequired(step.signoffs)) {
        return {
          tooltip: 'Cannot repeat steps with no signoffs',
          isDisabled: true,
        };
      }
      return {
        isDisabled: false,
      };
    },
    [canModifyContentStructure, isPending, step.signoffs]
  );

  const getStepSuggestEditDisabledData = useCallback(
    (values) => {
      if (!canModifyContentStructure) {
        return {
          tooltip: 'Cannot modify settings in this mode.',
          isDisabled: true,
        };
      }

      if (values.runAsBatch) {
        return {
          tooltip: 'Suggested edits cannot be made on batch steps.',
          isDisabled: true,
        };
      }
      return {
        isDisabled: false,
      };
    },
    [canModifyContentStructure]
  );

  const getSettings = useCallback(
    (values) => ({
      skip_step_enabled: {
        value: isStepSettingEnabled(values.skip_step_enabled, procedure.skip_step_enabled),
        isDefault: values.skip_step_enabled === undefined || values.skip_step_enabled === procedure.skip_step_enabled,
        isVisible: true,
        ...getSkipStepDisabledData(),
      },
      repeat_step_enabled: {
        value: isStepSettingEnabled(values.repeat_step_enabled, procedure.repeat_step_enabled),
        isDefault:
          values.repeat_step_enabled === undefined || values.repeat_step_enabled === procedure.repeat_step_enabled,
        ...getRepeatStepDisabledData(values),
        isVisible: true,
      },
      step_suggest_edits_enabled: {
        value: isStepSettingEnabled(values.step_suggest_edits_enabled, procedure.step_suggest_edits_enabled),
        isDefault:
          values.step_suggest_edits_enabled === undefined ||
          values.step_suggest_edits_enabled === procedure.step_suggest_edits_enabled,
        ...getStepSuggestEditDisabledData(values),
        isVisible: true,
      },
      runAsBatch: {
        value: values.runAsBatch,
        isVisible: !isPending && canBatchSteps,
        isDisabled: false,
      },
    }),
    [
      procedure.skip_step_enabled,
      procedure.repeat_step_enabled,
      procedure.step_suggest_edits_enabled,
      getSkipStepDisabledData,
      getRepeatStepDisabledData,
      getStepSuggestEditDisabledData,
      isPending,
      canBatchSteps,
    ]
  );

  const canOptimize = useCallback(
    (content) => {
      return optimize && !SKIP_VIRTUALIZATION_BLOCK_TYPES.includes(content.type);
    },
    [optimize]
  );

  const isDetailTypePresent = (type, values) => {
    if (values[type] !== undefined) {
      return true;
    }
    if (type === 'duration' && values['expected_duration'] !== undefined) {
      return true;
    }
    return false;
  };

  const renderStepDetails = (detailDefinition, values) => {
    if (detailDefinition.type === 'duration') {
      return (
        <VirtualizedElement
          scrollElementId={MainScrollPanelId}
          optimize={optimize}
          onRefChanged={fieldRef(`${step.id}.${detailDefinition.id}`)}
          namespace={PROCEDURE_EDIT_VIRTUALIZED_ELEMENTS}
        >
          <FieldSetStepDuration
            values={values}
            onRemove={onRemoveDetail}
            setFieldValue={getSetFieldValueAndSync()}
            errors={stepErrors?.duration}
            isDisabled={!canModifyContentStructure}
          />
        </VirtualizedElement>
      );
    } else if (detailDefinition.id === 'timer' && typeof values[detailDefinition.id] === 'object') {
      return (
        <VirtualizedElement
          scrollElementId={MainScrollPanelId}
          optimize={optimize}
          onRefChanged={fieldRef(`${step.id}.timer`)}
          namespace={PROCEDURE_EDIT_VIRTUALIZED_ELEMENTS}
        >
          <FieldSetStepTimer
            value={values[detailDefinition.id]}
            errors={stepErrors?.timer}
            onRemove={onRemoveDetail}
            isDisabled={!canModifyContentStructure}
          />
        </VirtualizedElement>
      );
    } else {
      return (
        <VirtualizedElement
          scrollElementId={MainScrollPanelId}
          optimize={optimize}
          onRefChanged={fieldRef(`${step.id}.${detailDefinition.id}`)}
          namespace={PROCEDURE_EDIT_VIRTUALIZED_ELEMENTS}
        >
          <FieldSetStepDetail
            key={detailDefinition.id}
            name={detailDefinition.id}
            icon={detailDefinition.icon}
            onRemove={onRemoveDetail}
            placeholder={detailDefinition.placeholder}
            title={detailDefinition.title}
            type={detailDefinition.type}
            values={values[detailDefinition.id]}
            stepErrors={stepErrors}
            isDisabled={!canModifyContentStructure}
          />
        </VirtualizedElement>
      );
    }
  };

  const getIsProcedureBlockDisabled = useCallback(
    (blockId) => {
      if (canModifyContentStructure || !step.conditionals || step.conditionals.length === 0) {
        return false;
      }

      return step.conditionals.some((conditional) => conditional.content_id === blockId);
    },
    [canModifyContentStructure, step.conditionals]
  );

  return (
    <>
      {step.shows_snippet_detached_message && (
        <div className="w-full app-bg-blue-note mt-2.5 px-2 py-1 rounded mb-1">{SNIPPET_STEP_DELETED_AND_DETACHED}</div>
      )}
      <TestPointSelectionModal
        visible={testPointSelectorContext.visible}
        onAdd={onAddTestPoints}
        onCancel={() =>
          setTestPointSelectorContext({
            visible: false,
            blockIndex: null,
          })
        }
      />
      <div
        className={`border-gray-200 border rounded bg-white ${changedStepIdSet?.has(step.id) ? 'animate-fadeIn' : ''} ${
          isStepHeadersEnabled ? 'pb-2' : 'py-2'
        }`}
        ref={fieldRef(`${step.id}.top`)}
      >
        {/* onSubmit dummy function needed to avoid runtime error:
        https://github.com/formium/formik/issues/2675 */}
        <Formik
          initialValues={initialValues}
          onSubmit={() => {}}
          validateOnChange={false}
          validate={syncStepToProcedure} // Takes care of syncing fields etc.
          enableReinitialize
        >
          {({ values, setFieldValue }) => (
            <Form>
              <div
                className="w-full"
                ref={fieldRef(`${step.id}`)}
                style={{ scrollMarginTop: `${EDIT_STICKY_HEADER_HEIGHT_REM}rem` }}
              >
                {isStepHeadersEnabled && values.headers && (
                  <>
                    {values.headers.map((header, headerIndex) => (
                      <Selectable
                        key={header.id}
                        boundary={Boundary.StepHeader}
                        block={header}
                        onKeyboard={(event) => onKeyboard(event, Boundary.StepHeader, headerIndex)}
                      >
                        {({ styles }) => (
                          <div className={styles}>
                            <FieldSetProcedureStepHeader
                              key={header.id}
                              stepHeaderValues={header}
                              stepHeaderErrors={stepErrors?.headers}
                              sectionIndex={sectionIndex}
                              stepIndex={stepIndex}
                              fieldRef={fieldRef}
                              onDragStart={onDragStart}
                              onDragEnd={onDragEnd}
                              validateForm={() => {}}
                              getSetFieldValueAndSync={getSetFieldValueAndSync}
                              setFieldValue={setFieldValue}
                              onRemoveContent={onRemoveContent}
                              onRemoveStepHeader={onRemoveStepHeader}
                              onAddBlock={onAddHeaderBlock}
                              syncHeaderToProcedure={syncHeaderToProcedure(headerIndex)}
                            />
                          </div>
                        )}
                      </Selectable>
                    ))}
                  </>
                )}
                <div aria-label="Step Body" role="region" className="relative p-2">
                  <OverlayUploadFileDrop
                    className="mt-2 flex flex-col grow"
                    isEnabled={canModifyContentStructure && isContentTypeEnabled(ProcedureContentBlockTypes.Attachment)}
                    onUpdate={addAttachment}
                  >
                    {/* File input handler */}
                    <input
                      ref={fileInputRef}
                      type="file"
                      id={`sections[${sectionIndex}].steps[${stepIndex}]._file_input`}
                      onChange={handleFileInputChange}
                      className="hidden"
                    />

                    <VirtualizedElement
                      scrollElementId={MainScrollPanelId}
                      optimize={optimize}
                      onRefChanged={fieldRef(`${step.id}.name`)}
                      namespace={PROCEDURE_EDIT_VIRTUALIZED_ELEMENTS}
                    >
                      <div className="flex flex-row items-end">
                        <div className="flex-none shrink-0 h-9 w-min">
                          <ExpandCollapseCaret
                            isExpanded={!isCollapsed}
                            onClick={toggleIsStepCollapsed}
                            ariaLabel={isCollapsed ? 'Expand Step' : 'Collapse Step'}
                          />
                        </div>

                        {/* Step key */}
                        <div className="flex-none flex items-center w-10 h-9 shrink-0">
                          <span className="flex justify-center items-center w-8 h-8 rounded-full bg-black font-bold text-xs text-white">
                            {stepKey}
                          </span>
                        </div>

                        <div
                          className="min-w-0 grow break-words"
                          style={{ scrollMarginTop: `${EDIT_STICKY_HEADER_HEIGHT_REM}rem` }}
                        >
                          <FieldSetProcedureField
                            fieldName="name"
                            fieldValue={values.name}
                            redlines={stepFieldRedlines}
                            fieldValueType="text"
                            placeholder="Step name*"
                            error={stepErrors?.redline}
                            acceptRedline={onAcceptStepRedlineField}
                            rejectRedline={onRejectStepRedlineField}
                          />
                        </div>
                        {showSettings && (
                          <ProcedureStepSettingsMenu
                            setSettings={getSetSettings()}
                            settings={getSettings(values)}
                            canResetSettings={canModifyContentStructure}
                          />
                        )}
                        {!showSettings && <div className="w-6" />}
                      </div>

                      {stepErrors && stepErrors.name && (
                        // ml-[4.5rem] === ml-18
                        <div className="flex text-sm text-red-700 ml-[4.5rem]">
                          <div>{stepErrors.name}</div>
                        </div>
                      )}
                    </VirtualizedElement>

                    {!isCollapsed && (
                      <div className="flex flex-col max-w-full">
                        <div ref={dependenciesRef} style={{ scrollMarginTop: `${EDIT_STICKY_HEADER_HEIGHT_REM}rem` }}>
                          <VirtualizedElement
                            scrollElementId={MainScrollPanelId}
                            optimize={optimize}
                            namespace={PROCEDURE_EDIT_VIRTUALIZED_ELEMENTS}
                          >
                            {/* Step dependencies */}
                            {step.dependencies?.length > 0 && (
                              <div>
                                <div className="flex mt-2">
                                  <div className="w-9 mr-2 mt-1 gap-y-2 flex flex-col text-gray-400"></div>
                                  {/* Render dependencies block */}
                                  <div className="flex flex-col rounded text-sm">
                                    <span className="field-title">Dependencies</span>
                                    <FieldSetStepDependencies
                                      dependencies={values.dependencies}
                                      path="dependencies"
                                      stepId={step.id}
                                      onDependenciesUpdated={syncDependenciesToProcedure}
                                      trackedCallback={trackedCallback}
                                      isDisabled={!areDependenciesEnabled || !canModifyContentStructure}
                                    />
                                  </div>
                                  {areDependenciesEnabled && canModifyContentStructure && (
                                    <button
                                      type="button"
                                      title="Remove Dependencies"
                                      onClick={getOnRemoveDependencies()}
                                      className="ml-2"
                                    >
                                      <FontAwesomeIcon
                                        icon="times-circle"
                                        className="text-gray-400 hover:text-gray-500"
                                      />
                                    </button>
                                  )}
                                </div>
                                {errors && errors.dependencies && (
                                  <div className="text-red-700 text-sm normal-case font-normal ml-11">
                                    {errors.dependencies}
                                  </div>
                                )}
                              </div>
                            )}
                          </VirtualizedElement>
                        </div>

                        <VirtualizedElement
                          scrollElementId={MainScrollPanelId}
                          optimize={optimize}
                          onRefChanged={fieldRef(`${step.id}.signoffs`)}
                          namespace={PROCEDURE_EDIT_VIRTUALIZED_ELEMENTS}
                        >
                          <>
                            {/* Signoffs*/}
                            <div className="flex pt-1 text-sm mt-2">
                              <div className="w-12 shrink-0"></div>
                              <div className="flex flex-col grow rounded">
                                <span className="field-title">Signoffs</span>
                                <FieldSetStepSignoffArray
                                  signoffs={values.signoffs}
                                  sectionIndex={sectionIndex}
                                  stepIndex={stepIndex}
                                  onSignoffsUpdated={syncSignoffsToProcedure}
                                  trackedCallback={trackedCallback}
                                  isDisabled={!canModifyContentStructure}
                                />
                                {stepErrors && stepErrors.signoffs && (
                                  <div className="text-sm text-red-700 normal-case font-normal">
                                    {stepErrors.signoffs}
                                  </div>
                                )}
                              </div>
                              <div className="w-7"></div>
                            </div>

                            {/* Details */}
                            {!hideDetails && (
                              <div className="flex pt-2">
                                <div className="w-12 shrink-0"></div>
                                <div className="flex flex-row w-full content-center space-x-2 items-end text-sm">
                                  <div className="w-full flex flex-wrap gap-x-2 items-start">
                                    {/* Add Details Button */}
                                    {canModifyContentStructure && (
                                      <div className="flex flex-col">
                                        <div className="h-4"></div>
                                        <div
                                          className="flex flex-row border border-gray-400 rounded relative cursor-pointer bg-white hover:bg-gray-100"
                                          onClick={showAddDetailsMenu}
                                        >
                                          <FontAwesomeIcon
                                            className="self-center ml-2 text-gray-500"
                                            icon="plus-circle"
                                          />
                                          <div className="whitespace-nowrap mx-2 py-2">Add Details</div>
                                          <div className="h-full px-3 my-2 border-l border-gray-300 text-gray-300 hover:text-gray-400">
                                            <FontAwesomeIcon icon="chevron-down" />
                                          </div>
                                          {isAddDetailsMenuVisible && (
                                            <div className="absolute top-9 left bg-white flex flex-col shadow-md border">
                                              <MenuContext
                                                menuContextActions={getDetailsContextActions()}
                                                className="font-medium text-xs max-h-[200px] overflow-auto"
                                              />
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    )}
                                    {/*/ Timing, Duration, Location, Channel */}
                                    {allStepDetails
                                      .filter((stepDetailDefinition) =>
                                        isDetailTypePresent(stepDetailDefinition.id, values)
                                      )
                                      .map((stepDetailDefinition) => (
                                        <div key={stepDetailDefinition.id}>
                                          {renderStepDetails(stepDetailDefinition, values)}
                                        </div>
                                      ))}
                                  </div>
                                </div>
                                <div className="w-7"></div>
                              </div>
                            )}
                          </>
                        </VirtualizedElement>

                        {/* Step content */}
                        <div>
                          <FieldArray
                            name="content"
                            key={`section.${sectionIndex}.steps.${stepIndex}.content`}
                            className="text-sm"
                            render={(contentArrayHelpers) => (
                              <div>
                                {canModifyContentStructure && values.content.length === 0 && (
                                  <div className="mt-4 ml-12">
                                    <AddContentMenu
                                      menuItems={getBlockAddContentItems({
                                        enabledContentTypes,
                                        canCopyBlock: false,
                                        canCutBlock: false,
                                        canPasteBlock: !!clipboardBlock,
                                        canDeleteBlock: false,
                                        hasConditionals: values.conditionals?.length > 0,
                                        hasDependencies: values.dependencies?.length > 0 || !areDependenciesEnabled,
                                        isTestProcedure: procedure?.procedure_type || !procedure?._id ? true : false,
                                        automationEnabled: procedure?.automation_enabled,
                                        isOnline: online,
                                      })}
                                      onClick={(menuItem) =>
                                        onContentMenuClick(menuItem, -1, step, syncStepToProcedure)
                                      }
                                      includeTextLabel={true}
                                    />
                                  </div>
                                )}

                                <DropWrapper
                                  onDragStart={onDragStart}
                                  onDragEnd={onDragEnd}
                                  contentArrayHelpers={contentArrayHelpers}
                                  step={step}
                                  syncStepToProcedure={syncStepToProcedure}
                                  sectionIndex={sectionIndex}
                                  stepIndex={stepIndex}
                                  isDisabled={!canModifyContentStructure}
                                >
                                  {({ snapshot }) => (
                                    <>
                                      {values.content &&
                                        values.content.map((content, contentIndex) => (
                                          <DragWrapper
                                            key={content.id}
                                            onKeyboard={onKeyboard}
                                            values={values}
                                            canModify={canModifyContentStructure}
                                            contentMenu={() => (
                                              <AddContentMenu
                                                menuItems={getBlockAddContentItems({
                                                  enabledContentTypes,
                                                  canPasteBlock: !!clipboardBlock,
                                                  hasConditionals: values.conditionals?.length > 0,
                                                  hasDependencies:
                                                    values.dependencies?.length > 0 || !areDependenciesEnabled,
                                                  isTestProcedure:
                                                    procedure?.procedure_type || !procedure?._id ? true : false,
                                                  automationEnabled: procedure?.automation_enabled,
                                                  isOnline: online,
                                                })}
                                                onClick={(menuItem) =>
                                                  onContentMenuClick(menuItem, contentIndex, step, syncStepToProcedure)
                                                }
                                              />
                                            )}
                                            removeContentButton={
                                              <button
                                                type="button"
                                                title="Remove Step Content"
                                                tabIndex={-1}
                                                onClick={() => onRemoveContent(contentIndex, step, syncStepToProcedure)}
                                              >
                                                <FontAwesomeIcon
                                                  icon="times-circle"
                                                  className="self-center text-gray-400 hover:text-gray-500"
                                                ></FontAwesomeIcon>
                                              </button>
                                            }
                                            snapshot={snapshot}
                                            content={content}
                                            contentIndex={contentIndex}
                                            showSubstepLabels={showSubstepLabels}
                                            subStepKeys={subStepKeys}
                                          >
                                            {({ styles, isSelected }) => (
                                              <VirtualizedElement
                                                scrollElementId={MainScrollPanelId}
                                                optimize={canOptimize(content)}
                                                overrideVirtualization={isSelected}
                                                onRefChanged={fieldRef(`${step.id}.content[${content.id}]`)}
                                                namespace={PROCEDURE_EDIT_VIRTUALIZED_ELEMENTS}
                                              >
                                                <div className={`${styles}`}>
                                                  {getStepBlockRedlines(content.id)?.length > 0 && (
                                                    <div className="p-2 flex flex-col gap-y-2">
                                                      {getStepBlockRedlines(content.id).map((redline) => (
                                                        <BlockRedline
                                                          redline={redline}
                                                          currentStep={step}
                                                          onAcceptRedline={(runRedline, sourceContentId) => {
                                                            onAcceptStepRedlineBlock(
                                                              `content[${contentIndex}]`,
                                                              content,
                                                              runRedline,
                                                              sourceContentId
                                                            );
                                                          }}
                                                          onRejectRedline={(runRedline) => {
                                                            onRejectStepRedlineBlock(
                                                              `content[${contentIndex}]`,
                                                              content,
                                                              runRedline
                                                            );
                                                          }}
                                                          sourceName=""
                                                        />
                                                      ))}
                                                    </div>
                                                  )}
                                                  <div
                                                    className="my-0.5 p-1 w-full"
                                                    style={{ scrollMarginTop: `${EDIT_STICKY_HEADER_HEIGHT_REM}rem` }}
                                                  >
                                                    {/* Render telemetry content block */}
                                                    {content.type.toLowerCase() ===
                                                      ProcedureContentBlockTypes.Telemetry && (
                                                      <FieldSetTelemetry
                                                        content={content}
                                                        path={`content[${contentIndex}]`}
                                                        setFieldValue={getSetFieldValueAndSync()}
                                                        contentErrors={getContentErrors(content.id)}
                                                        isDisabled={getIsProcedureBlockDisabled(content.id)}
                                                      />
                                                    )}
                                                    {/* Render commanding content block */}
                                                    {content.type.toLowerCase() ===
                                                      ProcedureContentBlockTypes.Commanding && (
                                                      <FieldSetCommanding
                                                        content={content}
                                                        path={`content[${contentIndex}]`}
                                                        setFieldValue={getSetFieldValueAndSync()}
                                                        contentErrors={getContentErrors(content.id)}
                                                      />
                                                    )}
                                                    {/* Render text content block */}
                                                    {content.type.toLowerCase() === ProcedureContentBlockTypes.Text && (
                                                      <>
                                                        <FieldSetText
                                                          block={content}
                                                          redlines={[]}
                                                          path={`content[${contentIndex}]`}
                                                          contentErrors={getContentErrors(content.id)}
                                                          acceptRedline={onAcceptStepRedlineBlock}
                                                          rejectRedline={onRejectStepRedlineBlock}
                                                          setFieldValue={getSetFieldValueAndSync()}
                                                        />
                                                      </>
                                                    )}
                                                    {/* Render field elements for block type */}
                                                    {/* Note: Blocks are being refactored to use new architecture, once
                                                        refactor is complete they will all use ProcedureBlockWithRedlining */}
                                                    {(content.type.toLowerCase() ===
                                                      ProcedureContentBlockTypes.FieldInput ||
                                                      content.type.toLowerCase() === ProcedureContentBlockTypes.Alert ||
                                                      content.type.toLowerCase() ===
                                                        ProcedureContentBlockTypes.Requirement ||
                                                      content.type.toLowerCase() ===
                                                        ProcedureContentBlockTypes.ExternalItem) && (
                                                      <>
                                                        <FieldSetProcedureBlock
                                                          block={content}
                                                          redlines={[]}
                                                          path={`content[${contentIndex}]`}
                                                          contentErrors={getContentErrors(content.id)}
                                                          setFieldValue={getSetFieldValueAndSync()}
                                                          acceptRedline={onAcceptStepRedlineBlock}
                                                          rejectRedline={onRejectStepRedlineBlock}
                                                          isDisabled={getIsProcedureBlockDisabled(content.id)}
                                                        />
                                                      </>
                                                    )}
                                                    {/* Render table input content block */}
                                                    {content.type.toLowerCase() ===
                                                      ProcedureContentBlockTypes.TableInput && (
                                                      <TableInputEdit
                                                        content={content}
                                                        path={`content[${contentIndex}]`}
                                                        setFieldValue={getSetFieldValueAndSync()}
                                                        errors={getContentErrors(content.id)}
                                                      />
                                                    )}
                                                    {/* Render procedure link (and section) */}
                                                    {content.type.toLowerCase() ===
                                                      ProcedureContentBlockTypes.ProcedureLink && (
                                                      <FieldSetProcedureLink
                                                        content={content}
                                                        contentIndex={contentIndex}
                                                        contentErrors={getContentErrors(content.id)}
                                                        setFieldValue={getSetFieldValueAndSync()}
                                                      />
                                                    )}
                                                    {content.type.toLowerCase() ===
                                                      ProcedureContentBlockTypes.JumpTo && (
                                                      <JumpToEdit
                                                        content={content}
                                                        contentErrors={getContentErrors(content.id)}
                                                        path={`content[${contentIndex}]`}
                                                        setFieldValue={getSetFieldValueAndSync()}
                                                        containingStepId={step.id}
                                                      />
                                                    )}
                                                    {content.type.toLowerCase() ===
                                                      ProcedureContentBlockTypes.Reference && (
                                                      <ReferenceBlockEdit
                                                        content={content}
                                                        contentErrors={getContentErrors(content.id)}
                                                        path={`content[${contentIndex}]`}
                                                        setFieldValue={getSetFieldValueAndSync()}
                                                        pendingStep={isPending ? step : null}
                                                        precedingStepId={precedingStepId}
                                                      />
                                                    )}
                                                    {/* Render expression content block */}
                                                    {content.type.toLowerCase() ===
                                                      ProcedureContentBlockTypes.Expression && (
                                                      <ExpressionBlockEdit
                                                        content={content}
                                                        contentErrors={getContentErrors(content.id)}
                                                        path={`content[${contentIndex}]`}
                                                        setFieldValue={getSetFieldValueAndSync()}
                                                        pendingStep={isPending ? step : null}
                                                        precedingStepId={precedingStepId}
                                                      />
                                                    )}
                                                    {/* Render file attachment */}
                                                    {content.type.toLowerCase() ===
                                                      ProcedureContentBlockTypes.Attachment && (
                                                      <FieldSetAttachment
                                                        attachment={content}
                                                        isPending={isPending}
                                                        path={`content[${contentIndex}]`}
                                                        setFieldValue={getSetFieldValueAndSync()}
                                                        isToTheSideImageEnabled={true}
                                                        source="Step"
                                                      />
                                                    )}
                                                    {/* Render part kit block */}
                                                    {content.type.toLowerCase() ===
                                                      ProcedureContentBlockTypes.PartKit && (
                                                      <PartKitFieldSet
                                                        content={content}
                                                        contentErrors={getContentErrors(content.id)}
                                                        path={`content[${contentIndex}]`}
                                                        setFieldValue={getSetFieldValueAndSync()}
                                                        teamId={currentTeamId}
                                                      />
                                                    )}
                                                    {/* Render part usage block */}
                                                    {content.type.toLowerCase() ===
                                                      ProcedureContentBlockTypes.PartUsage && (
                                                      <PartUsageFieldSet
                                                        content={content}
                                                        contentErrors={getContentErrors(content.id)}
                                                        path={`content[${contentIndex}]`}
                                                        setFieldValue={getSetFieldValueAndSync()}
                                                      />
                                                    )}
                                                    {/* Render part build (closeout) block */}
                                                    {content.type.toLowerCase() ===
                                                      ProcedureContentBlockTypes.PartBuild && (
                                                      <PartBuildFieldSet
                                                        content={content}
                                                        contentErrors={getContentErrors(content.id)}
                                                        path={`content[${contentIndex}]`}
                                                        setFieldValue={getSetFieldValueAndSync()}
                                                        teamId={currentTeamId}
                                                      />
                                                    )}
                                                    {/* Render inventory detail input block */}
                                                    {content.type.toLowerCase() ===
                                                      ProcedureContentBlockTypes.InventoryDetailInput && (
                                                      <InventoryDetailInputFieldSet
                                                        content={content}
                                                        contentErrors={getContentErrors(content.id)}
                                                        path={`content[${contentIndex}]`}
                                                        setFieldValue={getSetFieldValueAndSync()}
                                                      />
                                                    )}
                                                    {/* Render tool check-out block */}
                                                    {content.type.toLowerCase() ===
                                                      ProcedureContentBlockTypes.ToolCheckOut && (
                                                      <DraftToolCheckOutIn
                                                        content={content}
                                                        type="out"
                                                        contentErrors={getContentErrors(content.id)}
                                                        path={`content[${contentIndex}]`}
                                                        setFieldValue={getSetFieldValueAndSync()}
                                                      />
                                                    )}
                                                    {/* Render tool usage block */}
                                                    {content.type.toLowerCase() ===
                                                      ProcedureContentBlockTypes.ToolUsage && (
                                                      <DraftToolUsage
                                                        content={content}
                                                        contentErrors={getContentErrors(content.id)}
                                                        path={`content[${contentIndex}]`}
                                                        setFieldValue={getSetFieldValueAndSync()}
                                                      />
                                                    )}
                                                    {/* Render tool check-in block */}
                                                    {content.type.toLowerCase() ===
                                                      ProcedureContentBlockTypes.ToolCheckIn && (
                                                      <DraftToolCheckOutIn
                                                        content={content}
                                                        type="in"
                                                        contentErrors={getContentErrors(content.id)}
                                                        path={`content[${contentIndex}]`}
                                                        setFieldValue={getSetFieldValueAndSync()}
                                                      />
                                                    )}
                                                    {/* Render test matrix block */}
                                                    {content.type.toLowerCase() ===
                                                      ProcedureContentBlockTypes.TestCases && (
                                                      <TestCasesBlockEdit
                                                        content={content}
                                                        contentErrors={getContentErrors(content.id)}
                                                        path={`content[${contentIndex}]`}
                                                        setFieldValue={getSetFieldValueAndSync()}
                                                      />
                                                    )}
                                                    {content.type.toLowerCase() ===
                                                      ProcedureContentBlockTypes.FieldInputTable && (
                                                      <FieldInputTableEdit
                                                        content={content}
                                                        contentErrors={getContentErrors(content.id)}
                                                        path={`content[${contentIndex}]`}
                                                        setFieldValue={getSetFieldValueAndSync()}
                                                        canModifyContentStructure={canModifyContentStructure}
                                                      />
                                                    )}
                                                  </div>
                                                </div>
                                              </VirtualizedElement>
                                            )}
                                          </DragWrapper>
                                        ))}
                                    </>
                                  )}
                                </DropWrapper>
                              </div>
                            )}
                          />
                        </div>

                        {/* Step Flow Control */}
                        <div ref={conditionalsRef} style={{ scrollMarginTop: `${EDIT_STICKY_HEADER_HEIGHT_REM}rem` }}>
                          {values.conditionals && values.conditionals.length > 0 && (
                            <div className="flex my-2 ml-12">
                              <FieldSetStepConditionals
                                conditionals={values.conditionals}
                                errors={stepErrors?.conditionals}
                                content={values.content}
                                step={step}
                                onFieldRefChanged={onFieldRefChanged}
                                setFieldValue={getSetFieldValueAndSync()}
                                optimize={optimize}
                                isDisabled={!canModifyContentStructure}
                              />
                              {canModifyContentStructure && (
                                <div className="ml-2">
                                  <button type="button" title="Remove" onClick={getOnRemoveFlowControl()}>
                                    <FontAwesomeIcon
                                      icon="times-circle"
                                      className="self-center text-gray-400 hover:text-gray-500"
                                    />
                                  </button>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </OverlayUploadFileDrop>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <FlashMessage
        message={flashMessage}
        // @ts-ignore
        messageUpdater={setFlashMessage}
      />
    </>
  );
};

export default FieldSetProcedureStep;
